<template>
  <div>
    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            max-height="60%"
            src="../../assets/conteudo_cultura_alimentar_09.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Tainara Torres.
          </p>
        </v-col>
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              title="É na beira do fogão a lenha que a vida acontece"
              title_alignment="start"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Na casa mineira, a cozinha é o coração, e a vida se desenrola
                  ao seu redor. Em especial nas áreas rurais, é no fogão à lenha
                  que se prepara o café para começar o dia, que se cozinha o
                  almoço, que se aquece a água para um banho quente e que
                  esquenta, não só a cozinha, mas toda casa nos dias mais frios.
                  É comum o forno a lenha ser construído junto com o fogão, onde
                  a trempe com suas várias bocas repousa sobre a fornalha.
                </p>
                <p>
                  O fogão muitas vezes está no próprio corpo da casa, dentro da
                  cozinha ou em uma varanda onde também tem um tanque e ali os
                  cozidos e assados dividem o espaço com a área de serviço. Em
                  outros casos, é feita uma cobertura especialmente para o
                  fogão, na área externa e próximo da casa. Nas comunidades de
                  Mariana, é comum também ter uma cobertura (puxado) para
                  abrigar a lenha seca coletada nas matas, ficando ali protegida
                  da chuva.
                </p>
                <p>
                  O fogão é construído com tijolos maciços queimados e
                  assentados com argamassa. No corpo do fogão encontra-se a
                  fornalha onde a lenha queima e sobre ela a trempe de ferro
                  (chapa) onde são apoiadas as panelas. A caixa do forno, quando
                  existe, é feita no nível da trempe do fogão para que embaixo
                  dele fique o prolongamento da fornalha. Acima da caixa do
                  forno, encontra-se a chaminé, geralmente de manilha cerâmica,
                  por vezes cercada de alvenaria de tijolos. O revestimento é
                  variado, em grande parte de cimento queimado, em outros casos
                  com tijolinho ou cerâmica. Há também quem tenha construído o
                  forno separadamente do fogão, nesses casos são feitos com
                  tijolo queimado sobre uma base, revestido com argamassa de
                  barro e os mais antigos foram cobertos com terra de
                  cupinzeiro. Deles saem as quitandas caseiras e os assados.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Junto do fogão a lenha, é comum ser instalado o sistema de
                  aquecimento de água amplamente conhecido como “serpentina”. A
                  água aquecida serve os banheiros e, em alguns casos, também a
                  pia da cozinha. A água passa por uma tubulação de cobre
                  interna à fornalha onde é aquecida, sendo armazenada em um
                  reservatório cilíndrico a partir do qual é distribuída.
                </p>
                <p>
                  Em Minas Gerais, a cozinha é o ponto de encontro central das
                  famílias e amigos. Nas comunidades atingidas, isso não era
                  diferente. A boa cozinha mineira sempre reúne as pessoas em
                  volta do fogão a lenha para uma boa prosa, para planejar o
                  plantio e/ou a colheita, para saborear as deliciosas quitandas
                  da roça, para aquele café quentinho que ajuda a dar mais
                  fôlego no dia. O fogo, as cinzas e a fumaça são elementos que
                  compõem o dia a dia do cômodo de convivência da casa. Em certa
                  medida, a predileção por manter vivo o manuseio do fogo dá
                  continuidade ao ritual de se sentar em volta do fogo, de
                  observar as chamas.
                </p>
                <p>
                  Observa-se que os saberes e modos de fazer das comunidades
                  atingidas entrelaçam-se em grande medida ao modo de vida
                  rural. Sendo a lida com fogão a lenha um aspecto cultural
                  relevante, tendo em vista que o saber acender e controlar o
                  fogo de um fogão a lenha requer cotidiano, dedicação e
                  maestria. Uma vez expulsos de seus territórios, o fogão a
                  lenha passa a ocupar um espaço de memória e desejo na vida de
                  muitas atingidas e atingidos, já que o fogão a gás é a única
                  opção na vida urbana de Mariana, à qual muitas famílias foram
                  submetidas. Uma atingida moradora da comunidade de Bento
                  Rodrigues relata sobre as aptidões de sua avó:
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            max-height="60%"
            src="../../assets/conteudo_cultura_alimentar_10.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Fogão a lenha da comunidade de Camargos. Foto: Isabela Ladeira.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; color: white;" class="ma-0">
            “Fazia feijão, arroz, angu, ovo frito, couve e tomate, colhidos
            diretamente da horta e preparados no fogão a lenha. Era simples, mas
            muito gostoso. Agora isso acabou, sobrou apenas um fragmento de
            parede da cozinha da avó.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            max-height="60%"
            src="../../assets/conteudo_cultura_alimentar_11.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Brasa de um fogão a lenha. Foto: Acervo Cáritas.
          </p>
        </v-col>
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  E quando se trata de desejar ter novamente um fogão a lenha em
                  casa, essa garantia não é assegurada nos reassentamentos. Uma
                  atingida de Bento Rodrigues ressalta que a Fundação Renova
                  está desrespeitando o acordo judicial de restituir o modo de
                  vida das comunidades atingidas ao impor que nos
                  reassentamentos não serão construídos fogões à lenha
                  artesanal, como os que havia nas comunidades. O fogão a lenha
                  pré-moldado não agrada aos atingidos e atingidas pois não são
                  esses os que fazem parte da vida e história deles. Cada
                  família deve ter o direito de escolher como construir seu
                  fogão a lenha de modo que atenda às suas necessidades
                  específicas. Apesar de todos serem a lenha, os fogões
                  tradicionais que existiam nas comunidades não eram todos
                  iguais.
                </p>
                <p>
                  Um dos moradores da comunidade de Paracatu de Baixo descreve
                  que faziam ‘coisas’ tradicionais da comida mineira no fogão a
                  lenha, citando o frango com quiabo e tutu de feijão. Hoje,
                  morando na cidade, ainda fazem essas preparações, mas conforme
                  enfatizou o atingido, a essência de cozinhar na roça é
                  completamente diferente da cidade, onde não dispõe de fogão a
                  lenha. Acrescentou que o charme era o lugar, buscar lenha,
                  colher o quiabo, matar a galinha.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container mb-section mt-section">
      <v-row class="d-flex justify-space-around">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Além do aspecto cultural, o fogão a lenha também representa
                  uma economia significativa para as famílias que, em sua grande
                  maioria, coletam a própria lenha em seus terrenos e/ou em
                  matas da comunidade. Ou seja, além de não dependerem do gás de
                  cozinha, a lenha está disponível no cotidiano das comunidades.
                </p>
                <p>
                  Entre as comunidades tradicionais, o recurso lenha muitas
                  vezes é imprescindível no processo de produção de atividades
                  culturais que contribuem na renda familiar (REIS,
                  2015)<sup>1</sup>. O uso mais comum sem dúvidas é para
                  alimentar o fogão à lenha no dia a dia, mas a lenha também tem
                  extrema importância nas atividades de olaria desenvolvidas por
                  estas comunidades. A produção de tijolos, telhas, cerâmicas
                  utilitárias e produção de carvão, são alguns dos exemplos de
                  atividades diretamente relacionadas às condições
                  socioambientais da comunidade que influenciam na geração de
                  renda das famílias.
                </p>
                <p>
                  Por fim, não poderíamos deixar de citar que as cinzas
                  produzidas a partir da queima da lenha nos fogões tem destino
                  importante no modo de vida rural. Muitos atingidos relatam
                  usar as cinzas para adubar as plantas e enriquecer o solo,
                  assim como as benzedeiras das comunidades utilizam cinzas dos
                  fogões a lenha na prática da benzeção. As benzedeiras
                  mencionadas orientam-se pela fé católica e são lideranças
                  reconhecidas nessas comunidades (Paracatu de Baixo, Bento
                  Rodrigues e Ponte do Gama). Usam a benzeção para tratar males
                  do corpo e do espírito, como é de costume, valendo-se de
                  galhos de plantas, copos d’água e cinzas dos fogões a lenha.
                  (DUTRA, 2017)<sup>2</sup>.
                </p>
                <p style="text-align: center;">
                  <strong>
                    Autores: Isabela Fabiana da Silva Ladeira <sup>3</sup>,
                    Rafael Gustavo F. Pereira <sup>4</sup>, Ana Paula Alves
                    Ferreira
                    <sup>5</sup>
                  </strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_cultura_alimentar_12.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;">
            Foto: Tainara Torres / Acervo Revista Sirene (Ed. 32, novembro de
            2018).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> REIS, D. <strong>Modelagem do Barro</strong> Muquém.
              3. ed. Rio de Janeiro: IPHAN, 2015.
            </p>
            <p>
              <sup>2</sup> DUTRA, B.P.
              <strong
                >Diagnóstico preliminar dos bens culturais identificados no
                território atingido em Mariana pelo rompimento da barragem de
                Fundão</strong
              >. 2017. Relatório Técnico - Pólen Consultoria Patrimônio e
              Projetos Ltda. Lagoa Santa, 2017.
            </p>
            <p>
              <sup>3</sup> Engenheira Agrônoma (UFV), Assessora Técnica do
              Cadastro, Etapa 2 e 3.
            </p>
            <p>
              <sup>4</sup> Engenheiro Agrônomo (UFV), Assessor Técnico do
              Cadastro, Etapa 2 e 3.
            </p>
            <p>
              <sup>5</sup> Arquiteta e Urbanista (USP), Assessora Técnica do
              Cadastro, Etapas 2 e 3.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudo_soberania_quintandas' }"
            color="#EDBD43"
          >
            <strong>Conhecer "As Quintandas"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
};
</script>

<style scoped>
.v-main__wrap {
  color: #f9f7ed !important;
}

.mb-section {
  margin-bottom: 3em;
}

.mt-section {
  margin-top: 5em;
}

a {
  text-decoration: none;
  color: black;
}

.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-section {
    margin-top: 1em;
  }

  .title__img {
    width: 50%;
  }
  .border__image {
    background-color: #426e51;
    max-width: 50%;
    padding: 0;
    border-radius: 12px;
  }

  .border__image p {
    font-size: 0.6em;
    max-width: 100%;
    padding: 0 !important;
  }

  .border__image::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  max-width: 50%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_cultura_alimentar_03.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 2em 2em;
}
</style>
